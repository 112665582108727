.app {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;
min-height: 100vh;
min-width: 100vw;
}
@keyframes bounce {
	0%, 20%, 50%, 80%, 100% {transform: translateY(0);}
	40% {transform: translateY(-30px);}
	60% {transform: translateY(-15px);}
}
.bounceAnim{
  animation: bounce 3s ease-in-out infinite;
}