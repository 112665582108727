@media screen and (min-width: 770px) {
    .brandingMobile{
        display: none !important;
    }
    .loginContainer{
        width: 95vw !important;
        display: flex;
        flex-direction: row !important;
        align-items: center;
        height: 95vh !important;
        border-radius: 1rem;
        margin: 2.5vh 2.5vw 2.5vh 2.5vw;
        min-height: 0 !important;
    }
    .mainContainer{
        height: 100vh !important;
    }
    .formContainer{
        height: 100%;
    }
    .form{
        overflow-y: auto !important;
    }
}
@keyframes aparecer {
    0%{
        opacity: 0;
        translate: 0 -20px;
    }
    100%{
        opacity: 1;
        translate: 0 0;
    }
    
}

.loginContainer{
    animation: aparecer 1s ease-in;
    background-color: white;
    box-shadow: 0 0 30px rgba(0, 0, 0, 0.449);
    min-height: 100vh;
}
.textBold{
    font-weight: 500;
}
.hr{
    width: 50%;
    height: 1px;
    color: black;
}
.formContainer{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}
.form{
    height: auto;
    overflow-y: hidden;
    max-width: 12cm;
}
.brandingLarge{
    border-radius: 1rem 0 0 1rem;
    background-position: center;
    background-size: 80%;
    background-repeat: no-repeat;
    background-color: #9cd2ff;
}
.wrongPass{
    color: red;
    font-weight: 500;
}
.mainContainer{
    background: rgb(0,12,108);
    background: linear-gradient(61deg, rgba(0,12,108,1) 0%, rgba(84,98,255,1) 100%);
}