.loadContainer{
    transition: all 0.5s;
    width: 100vw;
    height: 100vh;
    display: flex;
    background-color: rgba(255, 255, 255, 0.827);
    position: fixed;
    z-index: 9999999;
}

.dotsCss {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 50px;
  }
  
  .dot {
    width: 20px;
    height: 20px;
    background-color: rgb(46, 26, 176);
    border-radius: 20px;
    transform: translate(0px, -40px);
    animation: bounce 1s infinite;
  }
  
  .dot:nth-child(2) {
    animation-delay: 0.2s;
  }
  
  .dot:nth-child(3) {
    animation-delay: 0.4s;
  }
  
  @keyframes bounce {
    0% {
      transform: translate(0px, -10px);
    }
    40% {
      width: 0px;
      height: 2px;
      transform: translate(0px, 40px) scale(1.7);
    }
    100% {
      height: 20px;
      transform: translate(0px, -20px);
    }
  }
  