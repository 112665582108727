@media screen and (min-width: 770px) {
}


.mainContainer{
    width: 100%;
    background-color: white;
    display: flex;
    align-items: center;
    flex-direction: column;
}
.hr{
    width: 90%;
}
.tableContainer{
    overflow: auto;
    max-width: 30cm;
}
.header{
    width: 100%;
    max-width: 35cm;
    margin-bottom: 1rem;
    display: grid;
    grid-template-columns: 30% 70%;
    padding: 1rem;
    border-bottom: 1px solid rgb(149, 149, 149) ;
}
.header button{
    min-width: 3cm;
    
}