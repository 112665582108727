.header{
    background-color: white;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.319);
    z-index: 99;
}
@keyframes opacity {
    0%{
        opacity: 0;
    }
    100%{opacity: 1;}
    
}
@keyframes opacityHide {
    0%{
        opacity: 1;
    }
    100%{opacity: 0;}
    
}
@keyframes slideLeft {
    0%{
        translate: -50px;
    }
    100%{translate: 0;}
    
}
@keyframes slideLeftHide {
    0%{
        translate: 0;
    }
    100%{translate: -50px;}
    
}
@keyframes slideRight {
    0%{
        translate: 50px;
    }
    100%{translate: 0;}
    
}
@keyframes slideRightHide {
    0%{
        translate: 0;
    }
    100%{translate: 50px;}
    
}
.offCanvasContainer{
    animation: opacity .5s ease-in-out;
    position: absolute;
    width: 100vw;
    height: 100%;
    min-height: 100vh;
    background-color: rgba(255, 255, 255, 0.292);
    display: flex;
    z-index: 999;
}
.hideOffCanvasContainer{
    animation: opacityHide .5s ease-in-out !important;
}
.hideOffCanvas{
    animation: slideLeftHide .5s ease-in-out !important;
    
}
.offCanvas{
    animation: slideLeft .5s ease-in-out;
    width: 50%;
    min-width: 250px;
    display: flex;
    flex-direction: column;
    height: 100%;
    background-color: white;
    box-shadow: 0 0 30px rgba(0, 0, 0, 0.486);
}
.offUserCanvasContainer{
    animation: opacity .5s ease-in-out;
    position: absolute;
    width: 100vw;
    height: 100%;
    min-height: 100vh;
    background-color: rgba(255, 255, 255, 0.292);
    display: flex;
    justify-content: end;
    z-index: 999;
}
.hideOffUserCanvasContainer{
    animation: opacityHide .5s ease-in-out !important;
}
.hideOffUserCanvas{
    animation: slideRightHide .5s ease-in-out !important;
    
}
.offUserCanvas{
    animation: slideRight .5s ease-in-out;
    width: 50%;
    min-width: 250px;
    display: flex;
    flex-direction: column;
    height: 100%;
    background-color: white;
    box-shadow: 0 0 30px rgba(0, 0, 0, 0.486);
}
.link{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: start;
    padding-left: 1rem;
    text-decoration: none;
    color: black;
    font-size: 1.2rem;
    font-weight: 500;
    border-top: 1px solid rgba(0, 0, 0, 0.239);
}
.linkActive{
    border-right: 2px solid rgb(0, 21, 210);
    color: rgb(0, 21, 210);
}
.offCanvasTitle{
    width: 100%;
    display: flex;
    align-items: center;
}
.offUserCanvasTitle{
    width: 100%;
    display: flex;
    align-items: center;
}
.mdLink{
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    color: black;
    height: 100%;
    font-weight: 500;
}
.mdLink:hover{
    background-color: rgb(215, 215, 215);
}
.mdLinkActive{
    background-color: rgb(215, 215, 215);
    border-bottom: 2px solid rgb(0, 21, 210);
    color: rgb(0, 21, 210);
}
.logout{
    transition: all 0.3s;
    color:red;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    border-radius: 1rem;
}
.logout:hover{
    transition: all 0.3s;
    color: rgb(101, 0, 0);
    background-color: rgb(211, 211, 211);
}