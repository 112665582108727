.mainContainer{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.mainView{
    width: 100%;
    background-color: rgb(255, 255, 255);
    display: flex;
    align-items: start;
    justify-content: center;
}