
.mainContainer{
    width: 100%;
    background-color: white;
    display: flex;
    align-items: center;
    flex-direction: column;
}
.hr{
    width: 90%;
}
.tableContainer{
    overflow: auto;
    max-width: 30cm;
}
.addModalContainer{
    position: fixed;
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: start;
    padding-top: 10rem;
    justify-content: center;
    background-color: rgba(255, 255, 255, 0.774);
    z-index: 9;
}

.addModal{
    background-color: white;
    box-shadow: 0 0 25px rgba(0, 0, 0, 0.487);
    width: 90%;
    max-width: 900px;
    display: flex;
    flex-direction: column;
}
.topBar{
    height: 20%;
    align-items: center;
    justify-content: center;
    padding: 1rem;
    padding-right: 1.5rem;
    padding-top: 1.5rem;
    font-size: 1.5rem;
    padding-bottom: 1.7rem;
    border-bottom: 1px solid rgb(216, 216, 216);
}
.mainText label{
    font-size: 1rem;
}
.mainText{
    width: 100%;
    text-align: center;
    align-items: center;
    justify-content: center;
    display: flex;
    height: 60%;
    font-size: 1.5rem;
    max-width: 90%;
    align-self: center;
    padding: 1rem;
}
.buttonsContainer{
    border-top: 1px solid rgb(216, 216, 216);
    display: flex;
    width: 100%;
    height: 20%;
    padding-right: 1rem;
    justify-content: end;
    align-items: center;
    padding: 1rem;
}
.buttonsContainer button{
    margin-left: 1rem;
}