
.mainContainer{
    width: 100%;
    background-color: white;
    display: flex;
    align-items: center;
    flex-direction: column;
}
.hr{
    width: 90%;
}
.tableContainer{
    overflow: auto;
    max-width: 30cm;
}

@keyframes expand {
    0%{
        opacity: 0;
        translate: 0 -20px;
    }
    50%{
        opacity: 0;
        translate: 0 -20px;
    }
    100%{
        opacity: 1;
        translate: 0 0px;
    }
    
}

.rowExpandAnim{
    animation: expand .2s ease-in-out;
}