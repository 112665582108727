
.mainContainer{
    width: 100%;
    max-width: 1500px;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.header{
    width: 100%;
    display: grid;
    grid-template-columns: 40% 60%;
    padding: 1rem;
    border-bottom: 1px solid rgb(149, 149, 149) ;
}
.header button{
    min-width: 3cm;
}