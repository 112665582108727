
@media screen and (min-width: 770px) {
    .topBar{
        justify-content: center !important;
    }

}

.mainContainer{
    width: 100%;
    max-width: 1500px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-left: 0.5rem;
    padding-right: 0.5rem;

}
.header{
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 1rem;
    justify-content: center;
    align-items: center;
    text-align: center;
    border-bottom: 1px solid rgb(149, 149, 149) ;
    position: relative;
}
.header button{
    min-width: 3cm;
}
.topBar{

    display: flex;
    align-items: center;
    justify-content: start;
    min-height: 1cm;
    overflow-x: auto;
    width: 100%;
}
.topBar .NavLink{
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    color: black;
    font-weight: 500;
    padding: 1rem;
}
.topBar .NavLinkActive{
    border-bottom: 2px solid rgb(0, 21, 210);
    color: rgb(0, 21, 210);
    background-color: rgb(215, 215, 215);
}
.topBar .NavLink:hover{
    background-color: rgb(215, 215, 215);
}